<template>
  <div class="ec-container px-160 txt-grey-900 pb-8">
    <BreadcrumbCpn :breadcrumb="breadcrumb" />
    <div
      class="bg-white mx-0 rounded h-full flex row"
      style="box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1); margin-top: 5px"
    >
      <div class="col-md-3 border-right p-0">
        <div class="p-2 border-bottom">
          <div class="d-flex justify-center">
            <b-avatar
              v-if="userLogin"
              size="80"
              variant="light-primary"
              :src="appUtils.getImageURL(userLogin.avatar || '')"
            />
          </div>
          <p
            class="mx-auto font-weight-bold text-dark text-center mb-0"
            style="font-size: 16px; width: fit-content; line-height: 24px"
          >
            {{ userLogin.name }}
          </p>
          <p
            class="mx-auto text-secondary text-center mb-0"
            style="font-size: 14px; width: fit-content; line-height: 21px"
            v-if="userLogin.doctor"
          >
            {{ userLogin.doctor.work }}
          </p>
          <p
            class="mx-auto text-warning font-weight-bold text-center mb-0"
            style="font-size: 14px; width: fit-content; line-height: 21px"
          >
            <svg
              style="transform: translateY(-2px)"
              width="15"
              height="17"
              viewBox="0 0 15 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.0086 2.88203C12.0656 1.26562 10.775 0.375 9.375 0.375H5.625C4.225 0.375 2.93438 1.26562 1.99141 2.88203C1.11016 4.39219 0.625 6.39062 0.625 8.5C0.625 10.6094 1.11016 12.6078 1.99141 14.118C2.93438 15.7344 4.225 16.625 5.625 16.625H9.375C10.775 16.625 12.0656 15.7344 13.0086 14.118C13.8898 12.6078 14.375 10.6094 14.375 8.5C14.375 6.39062 13.8898 4.39219 13.0086 2.88203ZM13.1086 7.875H10.6086C10.5598 6.59247 10.3044 5.32621 9.85234 4.125H12.2422C12.7203 5.17031 13.0359 6.46875 13.1086 7.875ZM11.4992 2.875H9.25469C8.99363 2.42212 8.67885 2.00242 8.31719 1.625H9.375C10.1562 1.625 10.8906 2.09375 11.4992 2.875ZM9.375 15.375H8.31953C8.6812 14.9976 8.99598 14.5779 9.25703 14.125H11.5016C10.8906 14.9062 10.1562 15.375 9.375 15.375ZM12.2422 12.875H9.85312C10.3052 11.6738 10.5605 10.4075 10.6094 9.125H13.1094C13.0359 10.5312 12.7203 11.8297 12.2422 12.875Z"
                fill="#FF8800"
              />
            </svg>
            {{ appUtils.numberFormat(points) }} Điểm
          </p>
        </div>
        <div class="py-1">
          <b-list-group style="margin: 0 5px">
            <b-list-group-item
              v-for="menu in menus"
              :key="menu.key"
              @click="handleClickMenu(menu.key)"
              :active="menu.active"
              class="border-0 rounded-sm d-flex justify-content-between align-items-center"
            >
              <span>
                <svg
                  style="transform: translateY(-2px); margin-right: 5px"
                  width="18"
                  height="22"
                  viewBox="0 0 18 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path :d="menu.icon" fill="#0C0C0D" />
                </svg>
                {{ menu.title }}
              </span>
              <svg
                style="transform: translateY(-1px)"
                width="10"
                height="18"
                viewBox="0 0 10 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.03055 9.53055L1.53055 17.0306C1.46087 17.1002 1.37815 17.1555 1.2871 17.1932C1.19606 17.2309 1.09847 17.2503 0.999929 17.2503C0.901383 17.2503 0.803801 17.2309 0.712756 17.1932C0.621712 17.1555 0.538986 17.1002 0.469303 17.0306C0.399621 16.9609 0.344345 16.8781 0.306633 16.7871C0.268921 16.6961 0.249512 16.5985 0.249512 16.4999C0.249512 16.4014 0.268921 16.3038 0.306633 16.2128C0.344345 16.1217 0.399621 16.039 0.469303 15.9693L7.43962 8.99993L0.469303 2.03055C0.328573 1.88982 0.249512 1.69895 0.249512 1.49993C0.249512 1.30091 0.328573 1.11003 0.469303 0.969304C0.610034 0.828573 0.800906 0.749512 0.999929 0.749512C1.19895 0.749512 1.38982 0.828573 1.53055 0.969304L9.03055 8.4693C9.10029 8.53896 9.15561 8.62168 9.19335 8.71272C9.23109 8.80377 9.25052 8.90137 9.25052 8.99993C9.25052 9.09849 9.23109 9.19608 9.19335 9.28713C9.15561 9.37818 9.10029 9.4609 9.03055 9.53055Z"
                  fill="#0C0C0D"
                />
              </svg>
            </b-list-group-item>
          </b-list-group>
        </div>
      </div>
      <div class="col-md-9 p-0">
        <component :is="activeComponent"></component>
      </div>
    </div>
  </div>
</template>

<script>
import BreadcrumbCpn from "@/components/Layout/BreadcrumbCpn.vue";
import appUtils from "@/utils/appUtils";
import {
  DEPOSIT_SCOPE,
  MARKETPLACE_USER_PROFILE_MENUS,
} from "@/utils/constant";
import { mapState } from "vuex";

export default {
  name: "UserProfile",
  components: { BreadcrumbCpn },
  data() {
    return {
      points: 0,
      appUtils,
      breadcrumb: [
        {
          text: "Trang chủ",
          to: {
            name: "Marketplace",
          },
        },
        {
          text: "Cá nhân",
        },
      ],
      menus: [...MARKETPLACE_USER_PROFILE_MENUS],
    };
  },
  async created() {
    const response = await this.$rf.getRequest("EcomRequest").getDoctorPoints({
      user_id: this.userLogin?.id,
      org_id: this.userLogin?.supplierInfo?.org_id,
      scope: this.userLogin?.supplierInfo?.workspace_id
        ? DEPOSIT_SCOPE.ws
        : DEPOSIT_SCOPE.org,
      scope_id:
        this.userLogin?.supplierInfo?.workspace_id ||
        this.userLogin?.supplierInfo?.org_id,
    });
    this.points = response.data.available_balance || 0;
    if (this.$route.query.menu) {
      this.menus.forEach((menu) => {
        menu.active = menu.key === this.$route.query.menu;
      });
    } else this.menus[0].active = true;
  },
  computed: {
    activeComponent() {
      return (
        this.menus.find((menu) => menu.active)?.component ||
        this.menus[0].component
      );
    },

    ...mapState({
      userLogin: (state) => state.authenticate.userLogin,
    }),
  },
  watch: {
    "$route.query.menu"(newVal) {
      this.handleClickMenu(newVal);
    },
  },
  methods: {
    handleClickMenu(key) {
      this.menus.forEach((menu) => {
        menu.active = menu.key === key;
      });
      this.$router
        .replace({ name: "UserProfile", query: { menu: key } })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.list-group {
  .list-group-item {
    &:hover {
      cursor: pointer;
    }
    &.active {
      color: #0c0c0d;
      background-color: #ebeffa;
      margin-top: 0px;
      &:hover {
        background-color: #e2e7f7;
      }
    }
  }
}
</style>